<template>
  <div class="card-sections-block-footer">
    <el-table
      class="card-summary-table"
      header-row-class-name="card-section-elements-table-footer"
      style="width: 100%; margin-bottom: 25px"
      :data="summary"
    >
      <el-table-column label="ИТОГО"></el-table-column>
      <el-table-column label="Вес" :width="width.weight">
        <template v-slot="scope">
          {{ scope.row.weight|numberFormat }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="this.card.status===this.$constants.card.statuses.STATUS_COMPLETED || (currentStage && currentStage.type === $constants.stage.types.TYPE_ASSESSMENT)"
        label="Процент премии"
        :width="width.fact"
      >
        <template v-slot="scope">
          {{ card.bonus|numberFormat }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

import SearchInput from "@/components/filters/SearchInput";
import CardsYearSelect from "@/components/filters/cards/CardsYearSelect";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import CardsPeriodSelect from "@/components/filters/cards/CardsPeriodSelect.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";
import CardsStatusSelect from "@/components/filters/cards/CardsStatusSelect.vue";
import {mapGetters} from "vuex";
import {downloader} from "@/mixins/downloader";
import CloneElementsFromCardModal from "@/components/cards/CloneElementsFromCardModal.vue";
import HistoryModal from "@/components/HistoryModal.vue";
import StartDynamicStageButton from "@/components/cards/StartDynamicStageButton.vue";

export default {
  name: "card-sections-block-footer",
  mixins: [downloader],
  components: {StartDynamicStageButton, HistoryModal, CloneElementsFromCardModal},
  props: {},

  computed: {
    ...mapGetters(['user']),
    ...mapGetters('card', {
      card: 'card'
    }),
    ...mapGetters('card/stages', {
      currentStage: 'currentStage',
    }),
    ...mapGetters('card/sections', {
      sections: 'sections',
      sectionsElements: 'sectionsElements',
    }),
    summary() {
      let totalWeight = 0;

      this.sections.forEach(section => {
        if( this.$companyConfiguration.cardSections.isSectionMultiplyElementsBonus(section.type) ){
          // Для кэфов не нужно считать вес
          return;
        }
        if( !this.sectionsElements[section.id] ){
          return;
        }
        this.sectionsElements[section.id].forEach(e => {
          totalWeight += +e.weight;
        })
      })

      return [{
        weight: +totalWeight.toFixed(2),
      }]
    },

    width() {
      let weightWidth = 0;
      let factWidth = 0;
      if (this.currentStage && this.currentStage.type === this.$constants.stage.types.TYPE_PLAN) {
        weightWidth = 970;
      }
      if (this.currentStage && this.currentStage.type === this.$constants.stage.types.TYPE_ASSESSMENT) {
        weightWidth = 1150;
        factWidth = 150;
      }
      if (this.card.status === this.$constants.card.statuses.STATUS_COMPLETED ) {
        weightWidth = 1150;
        factWidth = 150;
      }
      return {
        'weight': weightWidth,
        'fact': factWidth,
      }
    }
  },
  watch: {},
  data() {
    return {}
  },
  methods: {}
}
</script>


<style lang="scss">

</style>